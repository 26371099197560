import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from 'react-icons/md';
import { BsGithub, BsDiscord, BsPerson, BsLinkedin } from 'react-icons/bs';
import axios from 'axios'
import React, {useState} from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from '@chakra-ui/react';

export default function Contact({ color }) {
  const [contactName, setName] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()
    const contactData = {'contact_name' : contactName, 'contact_email' : contactEmail, 'message' : message};

    try {
      const response = await axios.post('https://ibrahim77.pythonanywhere.com/contact/', contactData);
      setIsSuccessPopupOpen(true);
      setName('')
      setEmail('')
      setMessage('')
    } catch (error) {
      console.log(error);
    }

  }

  // State to control the visibility of the success popup
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  // Function to close the success popup
  const closeSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
  };

  // JSX code for the success popup
  const successPopup = (
    <AlertDialog
      isOpen={isSuccessPopupOpen}
      onClose={closeSuccessPopup}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Success!
        </AlertDialogHeader>

        <AlertDialogBody>
          Thanks for contacting me! I'll get back to you as soon as I can.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button colorScheme="blue" onClick={closeSuccessPopup} ml={3}>
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  return (
    <Container bg="transparent" maxW="full" mt={0} centerContent overflow="hidden" id="contact" width='100%'>
      <Flex width='100%' justifyContent='center' alignItems='center'>
        <Box
          bg="transparent"
          borderColor={color}
          borderRadius="lg"
          borderWidth="5px"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}>
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem width={{base:'100%', sm:'100%', md:'inherit'}} textAlign='center'>
                <Box width='100%' >
                  <Heading >Contact</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                    Fill up the form below to contact
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="center">
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdPhone color={color} size="20px" />}
                        as="a"
                        href="tel:+923133141532">
                        +92-3133141532
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdEmail color={color} size="20px" />}
                        as="a"
                        href="mailto:syedibrahim4091@gmail.com"
                        target="_blank" 
                        rel="noopener noreferrer" >
                        syedibrahim4091@gmail.com
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdLocationOn color={color} size="20px" />}>
                        Karachi, Pakistan
                      </Button>
                    </VStack>
                  </Box>
                  <HStack
                    mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    px={5}
                    alignItems="flex-start"
                    justifyContent='center'>
                    <IconButton
                      as="a"
                      href="https://www.linkedin.com/in/syed-ibrahim-noman-866259199/"
                      target="_blank"
                      aria-label="LinkedIn"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#0D74FF' }}
                      icon={<BsLinkedin size="28px" />}
                    />
                    <IconButton
                      as="a"
                      href="https://github.com/ibrahim77gh"
                      target="_blank"
                      aria-label="github"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#0D74FF' }}
                      icon={<BsGithub size="28px" />}
                    />
                  </HStack>
                </Box>
              </WrapItem>
              <WrapItem width={{base:'100%', sm:'100%', md:'inherit'}}>
                <Box borderRadius="lg" alignItems='center' width='100%' justifyContent='center'>
                  <Box m={8} alignItems='center'  justifyContent='center'>
                    <VStack alignItems='center' justifyContent='center' spacing={5} as="form" onSubmit={handleSubmit}>
                      <FormControl id="name">
                        <FormLabel>Your Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsPerson color="gray.800" />}
                          />
                          <Input type="text" size="md" value = {contactName}
                            onChange = {(e) => setName(e.target.value)}/>
                        </InputGroup>
                      </FormControl>
                      <FormControl id="email">
                        <FormLabel>Email</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdOutlineEmail color="gray.800" />}
                          />
                          <Input type="text" size="md" value = {contactEmail}
                            onChange = {(e) => setEmail(e.target.value)}/>
                        </InputGroup>
                      </FormControl>
                      <FormControl id="message">
                        <FormLabel>Message</FormLabel>
                        <Textarea
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: 'gray.300',
                          }}
                          placeholder="message"
                          value = {message}
                          onChange = {(e) => setMessage(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="name" float="right">
                        <Button
                          type="submit"
                          variant="solid"
                          bg={color}
                          color="white"
                          _hover={{}}>
                          Send Message
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
      {isSuccessPopupOpen && successPopup}
    </Container>
  );
}
